import React from "react"
import { Corporation, PostalAddress } from "schema-dts"

export const address: PostalAddress = {
  "@type": "PostalAddress",
  addressCountry: "United Kingdom",
  addressLocality: "Covent Garden",
  addressRegion: "London",
  name: "Unroll Digital LTD",
  streetAddress: "71-75 Shelton Street",
  postalCode: "WC2H 9JQ",
}

export const makeCorporation = (
  logoSrc: string,
  email: string,
  description: string
): Corporation => {
  return {
    "@type": "Corporation",
    "@id": "https://corporation.unrolldigital.com",
    additionalType: "http://www.productontology.org/doc/Software_company",
    address: address,
    legalName: "Unroll Digital LTD",
    name: "Unroll Digital",
    duns: "225803721",
    logo: logoSrc,
    url: "https://unrolldigital.com",
    email: email,
    description: description,
    sameAs: [
      "https://twitter.com/unrolldigital",
      "https://www.instagram.com/unrolldigital/",
      "https://www.facebook.com/unrolldigital/",
      "https://beta.companieshouse.gov.uk/company/12535704",
    ],
  }
}

export const JSONLD = ({ data }: { data: Corporation }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(data),
    }}
  ></script>
)
