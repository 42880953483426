import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./index.module.css"
import logo from "../images/logo.svg"
import { makeCorporation, JSONLD } from "../components/json-ld"
import classnames from "classnames"

const IndexPage = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query HomepageMetaData {
      site {
        siteMetadata {
          title
          emailAddress
          siteAddress
          description
        }
      }
      file(relativePath: { eq: "logo-square.png" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const { emailAddress, siteAddress, description } = data.site.siteMetadata
  // Remove trailing slash from domain name ...
  const logoSquare = `${siteAddress.slice(0, -1)}${
    data.file.childImageSharp.fixed.src
  }`
  return (
    <Layout>
      <SEO title="Home" />
      <JSONLD data={makeCorporation(logoSquare, emailAddress, description)} />
      <WhatWeDo />
      <Services />
      <Contact emailAddress={emailAddress} />
      <Footer emailAddress={emailAddress} />
    </Layout>
  )
}

const Footer = ({ emailAddress }: ContactProps) => {
  const today = new Date()
  // TODO: Add postal address to site config ...
  return (
    <footer className="bg-blue-700 border-t-4 border-orange-400">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 md:flex md:justify-between md:flex-row">
        <div className="">
          <h4 className="text-base leading-6 text-blue-100 font-semibold tracking-wide uppercase">
            Contact Us
          </h4>
          <address className="not-italic mt-2 text-base leading-6 text-gray-200">
            Unroll Digital LTD
            <br />
            71-75 Shelton Street
            <br />
            Covent Garden <br />
            London
            <br />
            WC2H 9JQ
            <br />
            <a
              className="leading-6 text-blue-200 font-semibold tracking-wide  text-xs"
              href={`mailto:${emailAddress}`}
            >
              {emailAddress}
            </a>
          </address>
        </div>
        <div className="mt-6 md:mt-0 justify-end flex flex-col">
          <img src={logo} className="w-20" alt="Unroll Digital Logo" />
          <h4 className="leading-6 text-blue-100 font-semibold tracking-wide  text-xs">
            Unroll Digital <span className="">{today.getFullYear()}</span>
          </h4>
        </div>
      </div>
    </footer>
  )
}

const WhatWeDo = () => {
  return (
    <div className="bg-gray-200 px-4 sm:px-6 lg:px-8 py-12 " id="what">
      <div className="max-w-screen-xl mx-auto">
        <h4 className="text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase lg:text-center">
          What we do
        </h4>
        <h2 className="mt-2 max-w-3xl text-2xl leading-8 font-semibold font-display text-gray-900 sm:text-3xl sm:leading-9 lg:max-w-4xl lg:text-4xl lg:leading-10 lg:mx-auto lg:text-center ">
          We are a London based digital agency. We provide a wealth of
          experience in all areas of Visual Design and Software Development
        </h2>
        <div className="mt-8 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8 lg:mt-16">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-6 w-6  fill-current icon-code"
                >
                  <rect
                    width="18"
                    height="18"
                    x="3"
                    y="3"
                    className="primary"
                    rx="2"
                  ></rect>
                  <path
                    className={styles.iconSecondary}
                    d="M8.7 13.3a1 1 0 0 1-1.4 1.4l-2-2a1 1 0 0 1 0-1.4l2-2a1 1 0 1 1 1.4 1.4L7.42 12l1.3 1.3zm6.6 0l1.29-1.3-1.3-1.3a1 1 0 1 1 1.42-1.4l2 2a1 1 0 0 1 0 1.4l-2 2a1 1 0 0 1-1.42-1.4zm-3.32 3.9a1 1 0 0 1-1.96-.4l2-10a1 1 0 0 1 1.96.4l-2 10z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg leading-6 font-medium text-gray-900">
                Web Development
              </h4>
              <p className="mt-1 max-w-xl text-base leading-6 text-gray-500 lg:mt-2">
                We have experience maintaining large commercial bespoke systems
                with over 20 million monthly sessions. We have decades of
                experience with Wordpress and Salesforce
              </p>
            </div>
          </div>
          <div className="flex items-start mt-6 lg:mt-0">
            <div className="flex-shrink-0">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-6 w-6  fill-current icon-device-smartphone"
                  >
                    <path
                      className="primary"
                      d="M8 2h8a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2z"
                    ></path>
                    <path
                      className={styles.iconSecondary}
                      d="M12 20a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg leading-6 font-medium text-gray-900">
                Mobile App Development
              </h4>
              <p className="mt-1 max-w-xl text-base leading-6 text-gray-500 lg:mt-2">
                We develop for iOS, Android and React Native. We are happy to
                extend your existing apps or develop a new app for you from the
                ground up.
              </p>
            </div>
          </div>
          <div className="flex items-start mt-6 lg:mt-0">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-6 w-6  fill-current icon-shopping-basket"
                >
                  <path
                    className="primary"
                    d="M21.78 10l-2.81 11.24A1 1 0 0 1 18 22H6a1 1 0 0 1-.97-.76L2.22 10h19.56zM6.03 15.24l1 4a1 1 0 0 0 1.94-.48l-1-4a1 1 0 0 0-1.94.48zm10-.48l-1 4a1 1 0 0 0 1.94.48l1-4a1 1 0 0 0-1.94-.48zM11 15v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-2 0z"
                  ></path>
                  <rect
                    width="20"
                    height="4"
                    x="2"
                    y="8"
                    className="secondary"
                    rx="1"
                  ></rect>
                  <path
                    className="primary"
                    d="M9.7 9.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 1.4l-6 6z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg leading-6 font-medium text-gray-900">
                E-commerce
              </h4>
              <p className="mt-1 max-w-xl text-base leading-6 text-gray-500 lg:mt-2">
                We have expertise in the majority of main stream e-commerce
                platforms, including Shopify, WooCommerce and Magneto.
              </p>
            </div>
          </div>
          <div className="flex items-start mt-6 lg:mt-0">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-6 w-6 icon-trending-up fill-current"
                >
                  <path
                    className="primary"
                    d="M3.7 20.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l3.3 3.29 4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0L9 15.4l-5.3 5.3z"
                  ></path>
                  <path
                    className="secondary"
                    d="M16.59 8l-2.3-2.3A1 1 0 0 1 15 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1.7.7L18 9.42l-4.3 4.3a1 1 0 0 1-1.4 0L9 10.4l-5.3 5.3a1 1 0 1 1-1.4-1.42l6-6a1 1 0 0 1 1.4 0l3.3 3.3L16.59 8z"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="ml-4">
              <h4 className="text-lg leading-6 font-medium text-gray-900">
                Maintain existing software
              </h4>
              <p className="mt-1 max-w-xl text-base leading-6 text-gray-500 lg:mt-2">
                If your current agency isn't delivering at the level you expect.
                We can be utilised as a flexible in house Engineering / design
                team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
interface ContactProps {
  emailAddress: string
}

const Contact = ({ emailAddress }: ContactProps) => {
  return (
    <div className=" bg-gray-200" id="contact">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div>
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Let's discuss your next project
          </h2>
          <br />
          <p className="text-blue-600 text-xl font-extrabold leading-8  tracking-tight">
            We are happy to answer any questions you may have.{" "}
            <br className="hidden sm:block" />
            <a href={`mailto:${emailAddress}`} className="text-blue-800">
              Reach out over email
            </a>{" "}
            and we will get back to you to schedule a call or video conference
          </p>
        </div>

        <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href={`mailto:${emailAddress}`}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Email us
            </a>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            {/*
            Write a nice about page
            <Link
              to="/about"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Learn more
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}
const Services = () => {
  return (
    <div className="py-12 bg-white" id="why">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">
            Our Values
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Why are we different?
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            We believe getting the basics right is essential and you shouldn't
            have to reinvent the wheel each time you want to change a design or
            add a new feature. With a strong foundation in your software, new
            requirements become cheaper and easier to implement.
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-6 w-6 icon-trending-up fill-current"
                    >
                      <path
                        className="primary"
                        d="M3.7 20.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l3.3 3.29 4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0L9 15.4l-5.3 5.3z"
                      ></path>
                      <path
                        className="secondary"
                        d="M16.59 8l-2.3-2.3A1 1 0 0 1 15 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1.7.7L18 9.42l-4.3 4.3a1 1 0 0 1-1.4 0L9 10.4l-5.3 5.3a1 1 0 1 1-1.4-1.42l6-6a1 1 0 0 1 1.4 0l3.3 3.3L16.59 8z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    SEO by default
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    SEO isn't an afterthought or additional service, it is baked
                    into all web projects we deliver.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-6 w-6 icon-target fill-current"
                    >
                      <path
                        className="primary"
                        d="M15.23 2.53l-.35.35a3 3 0 0 0-.8 1.4 8.01 8.01 0 1 0 5.64 5.63 3 3 0 0 0 1.4-.79l.35-.35A9.99 9.99 0 0 1 12 22a10 10 0 1 1 3.23-19.47zM13.55 6.2L11.75 8a4 4 0 1 0 4.24 4.25l1.8-1.8a6 6 0 1 1-4.24-4.25z"
                      ></path>
                      <path
                        className="secondary"
                        d="M16 6.59V5a1 1 0 0 1 .3-.7l2-2A1 1 0 0 1 20 3v1h1a1 1 0 0 1 .7 1.7l-2 2a1 1 0 0 1-.7.3h-1.59l-4.7 4.7a1 1 0 0 1-1.42-1.4L16 6.58z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    Accurate project plans
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    You will never be in the dark, all projects are fully costed
                    in advance. With regular milestones all pre-agreed
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-6 w-6 fill-current icon-star"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        className="primary"
                      ></circle>
                      <path
                        className={classnames(
                          "secondary fill-white",
                          styles.iconSecondary
                        )}
                        d="M9.53 16.93a1 1 0 0 1-1.45-1.05l.47-2.76-2-1.95a1 1 0 0 1 .55-1.7l2.77-.4 1.23-2.51a1 1 0 0 1 1.8 0l1.23 2.5 2.77.4a1 1 0 0 1 .55 1.71l-2 1.95.47 2.76a1 1 0 0 1-1.45 1.05L12 15.63l-2.47 1.3z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    Experienced
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We are small but hugely experienced. All of that experience
                    will go into every project.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-6 w-6 fill-current icon-user-couple"
                    >
                      <path
                        className={styles.iconResearchSecondary}
                        d="M15 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm7 8a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                      ></path>
                      <path d="M9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm7 8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    User Research driven
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Are you not sure if the product you want to build is the
                    right fit? Let us ask real potential users and find out.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
